import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: "application/json",
    "sisternet-api-key": process.env.API_KEY,
  },
});

Vue.prototype.$http = axiosIns;

axiosIns.interceptors.request.use(
  (request) => {
    if (request.data) {
      // request.headers.common["Content-Type"] = "multipart/form-data";
    }
    return request;
  },
  (error) => Promise.reject(error)
);

export default axiosIns;
