const content = [
  {
    path: "/banks",
    name: "bank",
    component: () => import("@/views/content/bank/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Bank",
      breadcrumb: [
        {
          text: "Bank",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-bank/:uuid",
    name: "edit-bank",
    component: () => import("@/views/content/bank/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Bank",
      navActiveLink: "bank",
      breadcrumb: [
        {
          text: "Edit Bank",
          active: true,
        },
      ],
    },
  },
  {
    path: "/banks/:uuid/participant",
    name: "bank-participant",
    component: () => import("@/views/content/bank/participant/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Participant",
      navActiveLink: "bank",
      breadcrumb: [
        {
          text: "Bank",
          active: true,
        },
      ],
    },
  },
  {
    path: "/banks/:uuid/detail-pinjaman",
    name: "detail-bank-participant",
    component: () =>
      import("@/views/content/bank/participant/detail-participant.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Pinjaman",
      navActiveLink: "bank",
      breadcrumb: [
        {
          text: "Bank",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profile-mentor",
    name: "profile-mentor",
    component: () => import("@/views/content/profile-mentor/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Profile Mentor",
      breadcrumb: [
        {
          text: "Profile Mentor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/post",
    name: "post",
    component: () => import("@/views/content/post/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Post",
      breadcrumb: [
        {
          text: "Post",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-post",
    name: "create-post",
    component: () => import("@/views/content/post/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Post",
      navActiveLink: "post",
      breadcrumb: [
        {
          text: "Create Post",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-post/:uuid",
    name: "edit-post",
    component: () => import("@/views/content/post/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Post",
      navActiveLink: "post",
      breadcrumb: [
        {
          text: "Edit Post",
          active: true,
        },
      ],
    },
  },
  {
    path: "/post-category",
    name: "post-category",
    component: () => import("@/views/content/post-category/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Post Category",
      breadcrumb: [
        {
          text: "Post Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-learning",
    name: "e-learning",
    component: () => import("@/views/content/e-learning/index.vue"),
    meta: {
      auth: true,
      pageTitle: "E-Learning",
      breadcrumb: [
        {
          text: "E-Learning",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-e-learning",
    name: "create-e-learning",
    component: () => import("@/views/content/e-learning/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create E-Learning",
      navActiveLink: "e-learning",
      breadcrumb: [
        {
          text: "Create E-Learning",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-e-learning/:uuid",
    name: "edit-e-learning",
    component: () => import("@/views/content/e-learning/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit E-Learning",
      navActiveLink: "e-learning",
      breadcrumb: [
        {
          text: "Edit E-Learning",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-learning-category",
    name: "e-learning-category",
    component: () => import("@/views/content/e-learning-category/index.vue"),
    meta: {
      auth: true,
      pageTitle: "E-Learning Category",
      breadcrumb: [
        {
          text: "E-Learning Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-learning/content/:uuid",
    name: "e-learning-content",
    component: () => import("@/views/content/e-learning/content/index.vue"),
    meta: {
      auth: true,
      pageTitle: "E-Learning Content",
      navActiveLink: "e-learning",
      breadcrumb: [
        {
          text: "E-Learning",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-learning-content/question/elearning_uuid=:elearning_uuid&content_uuid=:content_uuid",
    name: "e-learning-content-question",
    component: () => import("@/views/content/e-learning/question.vue"),
    meta: {
      auth: true,
      pageTitle: "E-Learning Questions",
      navActiveLink: "e-learning",
      breadcrumb: [
        {
          text: "E-Learning",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/e-learning/participants/:uuid",
  //   name: "e-learning-participants",
  //   component: () => import("@/views/content/e-learning/participant.vue"),
  //   meta: {
  //     auth: true,
  //     pageTitle: "E-Learning Participants",
  //     navActiveLink: "e-learning",
  //     breadcrumb: [
  //       {
  //         text: "E-Learning",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/e-learning/chapter/:uuid",
    name: "e-learning-chapter",
    component: () => import("@/views/content/e-learning/chapter/index.vue"),
    meta: {
      auth: true,
      pageTitle: "E-Learning Chapter",
      navActiveLink: "e-learning",
      breadcrumb: [
        {
          text: "E-Learning",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-learning/:uuid/participant",
    name: "e-learning-participant",
    component: () => import("@/views/content/e-learning/participant/index.vue"),
    meta: {
      auth: true,
      pageTitle: "E-Learning Participant",
      navActiveLink: "e-learning",
      breadcrumb: [
        {
          text: "E-Learning",
          active: true,
        },
      ],
    },
  },
  {
    path: "/thread-question",
    name: "thread-question",
    component: () => import("@/views/content/thread-question/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Post Thread/Question",
      breadcrumb: [
        {
          text: "Post Thread/Question",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/content/user/index.vue"),
    meta: {
      auth: true,
      pageTitle: "User",
      breadcrumb: [
        {
          text: "User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-users",
    name: "create-users",
    component: () => import("@/views/content/user/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create User",
      navActiveLink: "users",
      breadcrumb: [
        {
          text: "Create User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-users/:uuid",
    name: "edit-users",
    component: () => import("@/views/content/user/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit User",
      navActiveLink: "users",
      breadcrumb: [
        {
          text: "Edit User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mentor",
    name: "mentor",
    component: () => import("@/views/content/mentor/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Mentor",
      breadcrumb: [
        {
          text: "Mentor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-mentor",
    name: "create-mentor",
    component: () => import("@/views/content/mentor/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Mentor",
      navActiveLink: "mentor",
      breadcrumb: [
        {
          text: "Create Mentor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-mentor/:uuid",
    name: "edit-mentor",
    component: () => import("@/views/content/mentor/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Mentor",
      navActiveLink: "mentor",
      breadcrumb: [
        {
          text: "Edit Mentor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-mentor/:uuid",
    name: "detail-mentor",
    component: () => import("@/views/content/mentor/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Mentor",
      navActiveLink: "mentor",
      breadcrumb: [
        {
          text: "Detail Mentor",
          active: true,
        },
      ],
    },
  },
  {
    path: "/schedule/:uuid",
    name: "schedule",
    component: () => import("@/views/content/mentor/schedule.vue"),
    meta: {
      auth: true,
      pageTitle: "List Schedule",
      navActiveLink: "mentor",
      breadcrumb: [
        {
          text: "List Schedule",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mentorship-booked-schedules",
    name: "mentorship-booked-schedules",
    component: () =>
      import("@/views/content/mentorship-booked-schedules/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Booked Schedule",
      breadcrumb: [
        {
          text: "Booked Schedule",
          active: true,
        },
      ],
    },
  },
  {
    path: "/webinar",
    name: "webinar",
    component: () => import("@/views/content/webinar/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Webinar",
      breadcrumb: [
        {
          text: "Webinar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-webinar",
    name: "create-webinar",
    component: () => import("@/views/content/webinar/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Webinar",
      navActiveLink: "webinar",
      breadcrumb: [
        {
          text: "Create Webinar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-webinar/:uuid",
    name: "edit-webinar",
    component: () => import("@/views/content/webinar/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Webinar",
      navActiveLink: "webinar",
      breadcrumb: [
        {
          text: "Edit Webinar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/webinar/:uuid/participant",
    name: "webinar-participant",
    component: () => import("@/views/content/webinar/participant/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Webinar Participant",
      navActiveLink: "webinar",
      breadcrumb: [
        {
          text: "Webinar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/competition",
    name: "competition",
    component: () => import("@/views/content/competition/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Competition",
      breadcrumb: [
        {
          text: "Competition",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-competition",
    name: "create-competition",
    component: () => import("@/views/content/competition/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Photo Video Competition",
      navActiveLink: "competition",
      breadcrumb: [
        {
          text: "Create Photo Video Competition",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-competition/:uuid",
    name: "edit-competition",
    component: () => import("@/views/content/competition/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Photo Video Competition",
      navActiveLink: "competition",
      breadcrumb: [
        {
          text: "Edit Photo Video Competition",
          active: true,
        },
      ],
    },
  },
  {
    path: "/set-winner-competition/:uuid",
    name: "set-winner-competition",
    component: () => import("@/views/content/competition/set-winner.vue"),
    meta: {
      auth: true,
      pageTitle: "Set Winner Photo Video Competition",
      navActiveLink: "competition",
      breadcrumb: [
        {
          text: "Set Winner Photo Video Competition",
          active: true,
        },
      ],
    },
  },
  {
    path: "/list-post/:uuid",
    name: "list-post",
    component: () => import("@/views/content/competition/list-post.vue"),
    meta: {
      auth: true,
      pageTitle: "List Post",
      navActiveLink: "competition",
      breadcrumb: [
        {
          text: "List Post",
          active: true,
        },
      ],
    },
  },
  {
    path: "/list-participant/:uuid",
    name: "list-participant",
    component: () => import("@/views/content/competition/list-participant.vue"),
    meta: {
      auth: true,
      pageTitle: "List Participant",
      navActiveLink: "competition",
      breadcrumb: [
        {
          text: "List Participant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/banner",
    name: "banner",
    component: () => import("@/views/content/banner/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Banner",
      breadcrumb: [
        {
          text: "Banner",
          active: true,
        },
      ],
    },
  },
  {
    path: "/journey",
    name: "journey",
    component: () => import("@/views/content/journey/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Journey",
      breadcrumb: [
        {
          text: "Journey",
          active: true,
        },
      ],
    },
  },
  {
    path: "/thematic-ui-setting",
    name: "thematic-ui-setting",
    component: () => import("@/views/content/thematic-ui-setting/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Thematic UI",
      breadcrumb: [
        {
          text: "Thematic UI",
          active: true,
        },
      ],
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("@/views/content/setting/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Setting",
      breadcrumb: [
        {
          text: "Setting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/version",
    name: "version",
    component: () => import("@/views/content/version/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Version",
      breadcrumb: [
        {
          text: "Version",
          active: true,
        },
      ],
    },
  },
  {
    path: "/comarch",
    name: "comarch",
    component: () => import("@/views/content/comarch/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Comarch Log",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/freebies-rewards-log",
    name: "freebies-rewards-log",
    component: () => import("@/views/content/freebies-rewards-log/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Freebies Rewards Log",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/toko-sister",
    name: "toko-sister",
    component: () => import("@/views/content/toko-sister/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Toko Sister",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-toko-sister/:uuid",
    name: "detail-toko-sister",
    component: () => import("@/views/content/toko-sister/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Toko Sister",
      navActiveLink: "toko-sister",
      breadcrumb: [
        {
          text: "Detail Toko Sister",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-toko-sister/product-detail/:uuid",
    name: "product-detail",
    component: () => import("@/views/content/toko-sister/product-detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Product Detail",
      navActiveLink: "toko-sister",
      breadcrumb: [
        {
          text: "Product Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/feature",
    name: "feature",
    component: () => import("@/views/content/feature/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Feature",
      breadcrumb: [
        {
          text: "Feature",
          active: true,
        },
      ],
    },
  },
  {
    path: "/intern-sis",
    name: "intern-sis",
    component: () => import("@/views/content/intern-sis/index.vue"),
    meta: {
      auth: true,
      pageTitle: "InternSis",
      breadcrumb: [
        {
          text: "InternSis",
          active: true,
        },
      ],
    },
  },
  {
    path: "/freebies-rewards",
    name: "freebies-rewards",
    component: () => import("@/views/content/freebies-rewards/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Freebies Rewards",
      breadcrumb: [
        {
          text: "Freebies Rewards",
          active: true,
        },
      ],
    },
  },
  {
    path: "/female-future-leader",
    name: "female-future-leader",
    component: () => import("@/views/content/female-future-leader/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Female Future Leader Information",
      breadcrumb: [
        {
          text: "Female Future Leader Information",
          active: true,
        },
      ],
    },
  },
  {
    path: "/female-future-leader-continue-register",
    name: "female-future-leader-continue-register",
    component: () =>
      import(
        "@/views/content/female-future-leader-continue-register/index.vue"
      ),
    meta: {
      auth: true,
      pageTitle: "Female Future Leader Continue Register",
      breadcrumb: [
        {
          text: "Female Future Leader Continue Register",
          active: true,
        },
      ],
    },
  },
  {
    path: "/redeem-history",
    name: "redeem-history",
    component: () => import("@/views/content/redeem-history/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Redeem History",
      breadcrumb: [
        {
          text: "Redeem History",
          active: true,
        },
      ],
    },
  },
  {
    path: "/logo-partner",
    name: "logo-partner",
    component: () => import("@/views/content/logo-partner/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Logo Partner",
      breadcrumb: [
        {
          text: "Logo Partner",
          active: true,
        },
      ],
    },
  },
  {
    path: "/female-future-leader-timeline",
    name: "female-future-leader-timeline",
    component: () =>
      import("@/views/content/female-future-leader-timeline/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Female Future Leader Timeline",
      breadcrumb: [
        {
          text: "Female Future Leader Timeline",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-female-future-leader-timeline/:uuid",
    name: "detail-female-future-leader-timeline",
    component: () =>
      import("@/views/content/female-future-leader-timeline/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Female Future Leader Timeline",
      navActiveLink: "female-future-leader-timeline",
      breadcrumb: [
        {
          text: "Detail Female Future Leader Timeline",
          active: true,
        },
      ],
    },
  },
  {
    path: "/female-future-leader-timeline-activity",
    name: "female-future-leader-timeline-activity",
    component: () =>
      import(
        "@/views/content/female-future-leader-timeline-activity/index.vue"
      ),
    meta: {
      auth: true,
      pageTitle: "Female Future Leader Timeline Activity",
      breadcrumb: [
        {
          text: "Female Future Leader Timeline Activity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-female-future-leader-timeline-activity",
    name: "create-female-future-leader-timeline-activity",
    component: () =>
      import(
        "@/views/content/female-future-leader-timeline-activity/create.vue"
      ),
    meta: {
      auth: true,
      pageTitle: "Create Female Future Leader Timeline Activity",
      navActiveLink: "female-future-leader-timeline-activity",
      breadcrumb: [
        {
          text: "Create Female Future Leader Timeline Activity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-female-future-leader-timeline-activity/:uuid",
    name: "edit-female-future-leader-timeline-activity",
    component: () =>
      import("@/views/content/female-future-leader-timeline-activity/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Female Future Leader Timeline Activity",
      navActiveLink: "female-future-leader-timeline-activity",
      breadcrumb: [
        {
          text: "Edit Female Future Leader Timeline Activity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/female-future-leader-participant",
    name: "female-future-leader-participant",
    component: () =>
      import("@/views/content/female-future-leader-participant/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Female Future Leader Participant",
      breadcrumb: [
        {
          text: "Female Future Leader Participant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-female-future-leader-participant/:uuid",
    name: "detail-female-future-leader-participant",
    component: () =>
      import("@/views/content/female-future-leader-participant/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Female Future Leader Participant",
      navActiveLink: "female-future-leader-participant",
      breadcrumb: [
        {
          text: "Detail Female Future Leader Participant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/community",
    name: "community",
    component: () => import("@/views/content/community/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Community",
      breadcrumb: [
        {
          text: "Community",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sister-talk-forum",
    name: "sister-talk-forum",
    component: () => import("@/views/content/sister-talk-forum/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Forum",
      breadcrumb: [
        {
          text: "Forum",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sister-talk-forum/:uuid",
    name: "sister-talk-forum",
    component: () => import("@/views/content/sister-talk-forum/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Forum Detail",
      navActiveLink: "sister-talk-forum",
      breadcrumb: [
        {
          text: "Forum Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sister-talk-category",
    name: "sister-talk-category",
    component: () => import("@/views/content/sister-talk-category/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mentorship-topic",
    name: "mentorship-topic",
    component: () =>
      import("@/views/content/mentorship-mentor/topic/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Topic List",
      breadcrumb: [
        {
          text: "Topic List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/schedule-mentor/:uuid",
    name: "schedule-mentor",
    component: () =>
      import("@/views/content/mentorship-mentor/topic/schedule.vue"),
    meta: {
      auth: true,
      pageTitle: "List Schedule",
      navActiveLink: "mentorship-topic",
      breadcrumb: [
        {
          text: "List Schedule",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mentorship-session",
    name: "mentorship-session",
    component: () =>
      import("@/views/content/mentorship-mentor/session/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Mentor Session",
      breadcrumb: [
        {
          text: "Mentor Session",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mentorship-session-chat/:uuid",
    name: "mentorship-session-chat",
    component: () =>
      import("@/views/content/mentorship-mentor/session/chat.vue"),
    meta: {
      auth: true,
      pageTitle: "Chat Session",
      navActiveLink: "mentorship-session",
      breadcrumb: [
        {
          text: "Chat Session",
          active: true,
        },
      ],
    },
  },
];

export default content;
