import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem("userData") &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * Check is user have permission.
 *
 * @param String permission
 *
 * @returns boolean
 */
export const checkPermission = (permission) => {
  if (permission == null) {
    return true;
  }
  const userPermission = JSON.parse(localStorage.getItem("userPermission"));
  return userPermission.includes(permission);
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = userRole => {
//   if (userRole === 'admin') return '/'
//   if (userRole === 'client') return { name: 'access-control' }
//   return { name: 'auth-login' }
// }

/**
 *
 * Standard success notification.
 *
 * @param {*} context This Context
 * @param String title
 * @param String message
 */
export const successNotification = (context, title, message) => {
  context.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {
      title,
      icon: "CheckIcon",
      variant: "success",
      text: message,
    },
  });
};

/**
 *
 * Standard error notification.
 *
 * @param {*} context This Context
 * @param String title
 * @param String|Array message
 */
export const errorNotification = (context, title, message) => {
  if (Array.isArray(message)) {
    message.forEach((item) => {
      context.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          icon: "DangerIcon",
          variant: "danger",
          text: item,
        },
      });
    });
  } else {
    context.$toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title,
        icon: "DangerIcon",
        variant: "danger",
        text: message,
      },
    });
  }
};
